@import "~antd-latest/dist/antd.less";

// NOTE: Overrides for some global styles antd-latest introduces and are not in compliance with our Vero styles
h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: 400;
}

body {
  line-height: 1.5;
}

@body-background: #F3F4F9;@primary-color: #2D2943;@secondary-color: #807E8D;@checkbox-color: #05CE88;@radio-dot-color: #05CE88;@radio-dot-disabled-color: #BFBEC6;@item-active-bg: #F9FAFC;@layout-body-background: #F3F4F9;@tabs-ink-bar-color: #05CE88;@typography-title-font-weight: 400;@select-item-selected-font-weight: 400;@font-family: 'Work Sans', sans-serif;@font-size-base: 15px;@input-height-base: 40px;@control-padding-horizontal: @padding-md;@input-hover-border-color: #BDC9D5;@border-color-base: #DEDCE5;@btn-font-weight: 500;@border-radius-base: 2px;@btn-height-base: 40px;@text-color-secondary: #807E8D;@tooltip-bg: #2D2943;@tooltip-arrow-width: 10px;@tooltip-max-width: 275px;@badge-dot-size: 8px;@popover-arrow-width: 10px;@picker-basic-cell-hover-color: red;@timeline-width: 1px;@timeline-color: #BFBEC6;@timeline-dot-border-width: 0;@timeline-dot-color: #BFBEC6;@timeline-dot-bg: #BFBEC6;@timeline-item-padding: 0 0 32px;