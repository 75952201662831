.lt-sm(@rules) {
  @media only screen and (max-width: @sm - 1px) {
    @rules();
  }
}

.lt-md(@rules) {
  @media only screen and (max-width: @md - 1px) {
    @rules();
  }
}
