.ant-table-expanded-row.magic-links-property-units-expanded,
.ant-table-expanded-row.magic-links-property-units-expanded:hover {
  background-color: #fff;
}

.ant-table-expanded-row.magic-links-property-units-expanded > td {
  padding-top: 0;
  padding-bottom: 0;
  border: none !important;
}

/* Left empty cell */

.ant-table-thead > tr > th.magic-links-property-name {
  padding-left: 25px;
}

.ant-table-tbody > tr > td.magic-links-property-name {
  padding-left: 0 !important;
}

.ant-table-thead > tr > th.magic-links-property-empty-left,
.ant-table-tbody > tr > td.magic-links-property-empty-left {
  padding-right: 0 !important;
}

.ant-table-tbody > tr > td.magic-links-property-empty-left {
  border: none !important;
}

/* Right empty cell */

.ant-table-thead > tr > th.magic-links-property-link,
.ant-table-tbody > tr > td.magic-links-property-link {
  padding-right: 0 !important;
}

.ant-table-thead > tr > th.magic-links-property-empty-right,
.ant-table-tbody > tr > td.magic-links-property-empty-right {
  padding-left: 0 !important;
}

.ant-table-tbody > tr > td.magic-links-property-empty-right {
  border: none !important;
}

/* Expanded row mobile*/
.magic-links-dashboard-properties-table .mobile-expanded-row {
  width: 100%;
}
