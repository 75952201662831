.recharts-cartesian-grid-horizontal line {
  stroke-dasharray: 0;
}

.recharts-cartesian-grid-vertical line:first-child {
  display: none;
}

.recharts-cartesian-grid-vertical line:last-child {
  display: none;
}

.recharts-legend-item-text {
  text-transform: capitalize;
}
