@import "./media-queries.less";

body {
  overflow-x: hidden;
  overflow-y: auto !important;
}

#root {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

.rjsf #root {
  position: relative;
}

b,
strong {
  font-weight: 500 !important;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: 400;
}

button {
  outline: none;
}

// Ignore title in Styleguidist
h1:not([class^="rsg"]) {
  font-size: 28px;
  margin-bottom: 32px;

  .lt-sm({font-size: 24px;});
}

h2 {
  font-size: 22px;

  .lt-sm({font-size: 20px;});
}

.ant-divider-vertical {
  height: 26px;
  margin: 0 20px;
}

.ant-tooltip {
  z-index: 2500;
  .ant-tooltip-inner {
    border-radius: 2px;
    padding: 12px 16px;

    ul {
      padding-left: 1rem;
      margin-bottom: 0;
    }
  }

  a {
    color: @tooltip-text-color;
    border-bottom: 1px solid @tooltip-text-color;
  }
}

.ant-tooltip.unverified-tooltip {
  .ant-tooltip-inner {
    font-weight: 400;
    color: rgba(0, 0, 0, 0.65);
    background-color: #fff;
  }
  .ant-tooltip-arrow::before {
    background-color: #fff;
  }

  .tooltip-inner-gray {
    background-color: #f9fafc;
    padding: 10px;
  }
}

.ant-popover.success-popover {
  .ant-popover-inner {
    border-radius: 2px;
    padding: 12px 16px;
    font-weight: 400;
    color: #2d2943;
    background-color: #ace3d1;

    ul {
      padding-left: 1rem;
      margin-bottom: 0;
    }
  }

  .ant-popover-arrow {
    background-color: #ace3d1;
    border-color: #ace3d1 !important;
  }

  a {
    color: @tooltip-text-color;
    border-bottom: 1px solid @tooltip-text-color;
  }
}

.ant-popover.dark-theme {
  .ant-popover-inner-content {
    margin-top: 10px;
  }

  .ant-popover-arrow {
    background-color: #2d2943;
    border-color: #2d2943 !important;
    margin-top: 10px;
  }
}

.ant-badge-dot {
  background-color: @badge-background-color;
}

.ant-dropdown {
  &-placement-bottomRight .ant-dropdown-menu::before {
    top: 0;
    left: 80%;
    transform: rotate(45deg) translate(-50%);
  }

  &-placement-topRight .ant-dropdown-menu::before {
    bottom: 0;
    left: 80%;
    transform: rotate(45deg) translate(50%);
  }

  .ant-dropdown-menu {
    background-color: @dropdown-menu-background-color;
    border-radius: 2px;
    min-width: 175px;

    &::before {
      position: absolute;
      border-radius: 2px;
      display: block;
      content: " ";
      width: 15px;
      height: 15px;
      background-color: @dropdown-menu-background-color;
      z-index: -1;
    }
  }

  .ant-dropdown-menu-item {
    color: @dropdown-menu-text-color;

    &:hover {
      background-color: @dropdown-menu-item-hover-background-color;
    }

    font-size: 15px;
    padding-top: 8px;
    padding-bottom: 8px;
  }
  .ant-select-dropdown-menu-item-group-title {
    color: pink;
  }
  .light-dropdown {
    &.ant-dropdown-menu {
      background-color: @dropdown-light-menu-background-color;

      &::before {
        background-color: @dropdown-light-menu-background-color;
      }

      .ant-dropdown-menu-item {
        color: @dropdown-light-menu-text-color;

        &:hover {
          background-color: @dropdown-light-menu-item-hover-background-color;
        }
      }
    }
  }
}

.ant-popover {
  position: fixed;

  .ant-popover-inner {
    border-radius: 2px;
  }

  .ant-popover-inner-content {
    padding: 0;
  }

  .ant-popover-title {
    padding: 15px 24px;
    height: 70px;
    display: flex;
    justify-content: space-between;
  }

  .ant-popover-arrow {
    border-color: @popover-header-background-color !important;
  }
}

.ant-popover-gray-header {
  .ant-popover-arrow {
    border-color: @popover-gray-header-background-color !important;
  }

  .ant-popover-title {
    background-color: @popover-gray-header-background-color;
  }
}

.ant-badge-dot {
  background-color: @badge-dot-background-color !important;
}

.ant-notification {
  margin: 0;
  left: 50%;
  transform: translateX(-50%);
  width: 956px;
  max-height: 58px;

  &-close-x {
    display: flex;
  }

  &-notice {
    border-radius: 2px;
    box-shadow: 0px 3px 9px rgba(0, 0, 0, 0.1);

    &.info {
      background-color: @notification-info-background-color;
    }

    &.warning {
      background-color: @notification-warning-background-color;
    }

    &.error {
      background-color: @notification-error-background-color;
    }

    &-close {
      display: flex;
      align-items: center;
      height: 100%;
      top: 0;
    }

    &-message {
      display: none !important;
    }

    &-description {
      padding-right: 24px;
      display: inline-block;
      text-align: center;
      width: 100%;
    }
  }
}

.ant-calendar-date {
  &[aria-selected="true"] {
    background-color: @datepicker-calendar-day-background-color !important;
    border-color: @datepicker-calendar-day-border-color !important;
    color: @datepicker-calendar-day-text-color !important;
  }

  &:not([aria-selected="true"]):hover {
    background-color: @datepicker-calendar-day-hover-background-color !important;
  }
}

.ant-calendar-input-wrap {
  .lt-sm({display: none !important});
}

span.ant-radio + * {
  padding-left: 14px !important;
  padding-right: 0 !important;
  vertical-align: middle;
}

.ant-radio-wrapper {
  margin-right: 24px !important;
}

.ant-tag {
  border: 0 !important;
  background-color: @tag-default-background-color !important;
  color: @tag-default-text-color !important;
  vertical-align: middle;
  padding: 0 8px !important;

  &.ant-tag-green {
    background-color: @tag-green-background-color !important;
  }

  &.ant-tag-red {
    background-color: @tag-red-background-color !important;
  }

  &.ant-tag-orange {
    background-color: @tag-orange-background-color !important;
  }
}

svg:not(.recharts-surface):not([id^="loader"]),
svg:not(.recharts-surface):not([id^="loader"]) path {
  transition: @transition-default;
}

.ant-progress {
  line-height: 0;
}

.ant-progress-inner {
  border-radius: 0;
}

.ant-input:focus {
  box-shadow: "none";
}

#hubspot-messages-iframe-container {
  iframe::selection {
    background-color: transparent;
  }
  &.widget-align-right {
    bottom: 80px !important;
  }
}

.ant-popover.custom-width-popover .ant-popover-inner-content > div {
  max-width: none;
}

h4.ant-typography,
.ant-typography h4 {
  margin: unset;
}

.ant-typography + h4.ant-typography {
  margin-top: unset;
}

.action-icon:hover {
  cursor: pointer;
  &[disabled] {
    cursor: not-allowed;
  }
}

input,
textarea,
button,
a,
svg {
  &[disabled] {
    cursor: not-allowed;
  }
}

.cursor-arrow:hover {
  cursor: default;
}

.ReactQueryDevtools {
  button {
    bottom: 25px !important;
  }
}

.row-margin-space {
  .lt-md({margin-top: 8px !important;});
}

.row-margin-space-double {
  .lt-md({margin-top: 16px !important;});
}

// NOTE: we are hardcoding intercom padding because it's not working for mobile
// https://developers.intercom.com/installing-intercom/docs/javascript-api-attributes-objects#messenger-attributes
.intercom-launcher {
  .lt-md({
    bottom: 73px !important;
    right: 25px !important;
  });
}
