/************************************************************************************

  Utility classes to be reused in the project.
  A concept borrowed from Bootstrap. (e.g. https://getbootstrap.com/docs/5.0/utilities/spacing/)
  Free to extend this file with new util classes.

**************************************************************************************/

.text-ellipsis {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  display: block !important;
}

.text-nowrap {
  white-space: nowrap;
}

.text-break {
  word-break: break-word;
}

.text-centered {
  text-align: center;
}

.pt-0 {
  padding-top: 0 !important;
}

.pl-0 {
  padding-left: 0 !important;
}

.pr-0 {
  padding-right: 0 !important;
}

.pb-0 {
  padding-bottom: 0 !important;
}

.w-100 {
  width: 100% !important;
}
